var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.googleCourse
    ? _c("div", { key: `key_${_vm.key}` }, [
        _c(
          "div",
          {
            staticClass:
              "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
          },
          [
            _vm.isMultipleEdit
              ? _c("div", { staticClass: "kt-portlet" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "kt-portlet__head kt-portlet__head--lg" },
                      [
                        _c("div", { staticClass: "kt-portlet__head-label" }, [
                          _c("h3", { staticClass: "kt-portlet__head-title" }, [
                            _vm._v(" Multi Course Assignment "),
                            _c("small", [
                              _vm._v(
                                " Editing " +
                                  _vm._s(_vm.linkedAssignments.length) +
                                  " course assignments "
                              ),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                          _c("div", { staticClass: "kt-portlet__head-group" }, [
                            _vm.canApplyToAll
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group row mb-0 mr-3 mt-1",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "col-9 col-form-label text-right",
                                      },
                                      [_vm._v(" Save to all courses ")]
                                    ),
                                    _c("div", { staticClass: "col-3" }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-switch kt-switch--outline kt-switch--icon kt-switch--brand",
                                        },
                                        [
                                          _c("label", [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.isMultipleEdit,
                                                  expression: "isMultipleEdit",
                                                },
                                              ],
                                              attrs: {
                                                type: "checkbox",
                                                checked: "checked",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.isMultipleEdit
                                                )
                                                  ? _vm._i(
                                                      _vm.isMultipleEdit,
                                                      null
                                                    ) > -1
                                                  : _vm.isMultipleEdit,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.isMultipleEdit,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.isMultipleEdit =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.isMultipleEdit =
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            ))
                                                    }
                                                  } else {
                                                    _vm.isMultipleEdit = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span"),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "kt-portlet__body" },
                      [
                        _vm.primaryAssignment &&
                        _vm.primaryAssignment.googleCourseId
                          ? _c("GoogleCourseWork", {
                              attrs: {
                                course: _vm.primaryAssignment.googleCourse,
                                assignment: _vm.primaryAssignment,
                                "linked-assignments": _vm.linkedAssignments,
                                idx: 0,
                                "is-multiple-edit":
                                  _vm.isMultipleEdit &&
                                  _vm.linkedAssignments.length > 1,
                                "on-course-work-saved": _vm.onCourseWorkSaved,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ])
              : _c(
                  "div",
                  { staticClass: "kt-portlet" },
                  [
                    _c(
                      "b-tabs",
                      {
                        staticClass: "kt-portlet__body",
                        attrs: { pills: "", "nav-class": "mb-0" },
                      },
                      _vm._l(
                        _vm.linkedAssignments,
                        function (assignment, index) {
                          return _c(
                            "b-tab",
                            {
                              key: `googleCourseWorkId_${assignment.googleCourseWorkId}_${_vm.key}`,
                              attrs: {
                                active: _vm.activeTabIndex == index,
                                title: assignment.googleCourse.googleCourseName,
                              },
                              model: {
                                value: _vm.activeTabIndex,
                                callback: function ($$v) {
                                  _vm.activeTabIndex = $$v
                                },
                                expression: "activeTabIndex",
                              },
                            },
                            [
                              _c("GoogleCourseWork", {
                                attrs: {
                                  course: assignment.googleCourse,
                                  assignment: assignment,
                                  "linked-assignments": _vm.linkedAssignments,
                                  idx: index,
                                  "is-multiple-edit":
                                    _vm.isMultipleEdit &&
                                    _vm.linkedAssignments.length > 1,
                                  "on-course-work-saved": _vm.onCourseWorkSaved,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }