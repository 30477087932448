const courseWorkMixins = {
    methods: {
        $_courseWorkMixins_getCourseWork(course) {
            const { database } = this.$store.state;
            return getCourseWork(database, course);
        },
        $_courseWorkMixins_getCourseWorkFromRoute() {
            if (this.$route.params.courseWorkId) {
                const { courseWorkId } = this.$route.params;
                return this.$store.state.database.courseWork.find((cw) => cw.courseWorkId == courseWorkId) || null;
            }
            if (this.$route.params.googleCourseWorkId) {
                const { googleCourseWorkId } = this.$route.params;
                return this.$store.state.database.googleCourseWork.find((cw) => cw.googleCourseWorkId == googleCourseWorkId) || null;
            }
            return null;
        },
        $_courseWorkMixins_getGoogleCourseWorkFromRoute() {
            const { googleCourseWorkId } = this.$route.params;
            return this.$store.state.database.googleCourseWork.find((cw) => cw.googleCourseWorkId == googleCourseWorkId) || null;
        },
    },
};

function getCourseWork(database, course) {
    const { gradeTemplate } = course;

    const googleCourseId = course.googleCourseId || 0;
    const gradeTemplateId = gradeTemplate ? gradeTemplate.gradeTemplateId : 0;

    if (!gradeTemplateId) {
        window.console.error('Could not find grade template for course');
        return [];
    }

    // build courseSectionIds for combos
    const { courseSectionIds } = course;

    let output = [];

    courseSectionIds.forEach((courseSectionId) => {
        output = [
            ...output,
            ...getLocalCourseWork(database, { courseSectionId }),
        ];
    });

    if (googleCourseId) {
        const googleCourseWork = getGoogleCourseWork(database, { googleCourseId });
        output = [
            ...output,
            ...googleCourseWork,
        ];
    }

    return output.sort((a, b) => (`${a.publishDate}${a.publishTime || '12:00 AM'}` > `${b.publishDate}${b.publishTime || '12:00 AM'}` ? 1 : -1));
}

function getGoogleCourseWork(database, { googleCourseId }) {
    const googleCourseWork = database.googleCourseWork.filter((cw) => googleCourseId && cw.googleCourseId == googleCourseId);
    return googleCourseWork;
}

function getLocalCourseWork(database, { courseSectionId }) {
    const courseWork = database.courseWork.filter((cw) => cw.courseSectionId == courseSectionId);
    return courseWork;
}

export {
    courseWorkMixins as default,
    getCourseWork,
    courseWorkMixins,
};
