var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return (_vm.isMultipleEdit && _vm.isPrimary) || !_vm.isMultipleEdit
    ? _c(
        "div",
        [
          !_vm.isMultipleEdit
            ? _c("div", {
                staticClass:
                  "kt-separator kt-separator--space-md kt-separator--border-dashed",
              })
            : _vm._e(),
          _c(
            "b-overlay",
            {
              attrs: { "no-center": "", show: _vm.saving },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _vm.saving
                          ? _c("div", { staticClass: "kt-portlet__body" }, [
                              _c(
                                "div",
                                { staticClass: "text-center" },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "stopwatch",
                                      "font-scale": "3",
                                      animation: "cylon",
                                    },
                                  }),
                                  _c("h6", { staticClass: "mt-4" }, [
                                    _vm._v(" Saving changes... "),
                                  ]),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                4222023643
              ),
            },
            [
              _c("div", { staticClass: "kt-portlet__body pt-0" }, [
                _c(
                  "div",
                  { staticClass: "row form-group validated" },
                  [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("span", { staticClass: "kt-font-danger" }, [
                        _vm._v("* "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("pages.teacher.assignments.markingPeriod")
                          )
                        ),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.courseWork.schoolTermMarkingPeriodId,
                              expression:
                                "courseWork.schoolTermMarkingPeriodId",
                            },
                          ],
                          staticClass: "form-control",
                          class: { "is-invalid": _vm.errors.markingPeriod },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.courseWork,
                                "schoolTermMarkingPeriodId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.markingPeriodSelect, function (option) {
                          return _c(
                            "option",
                            {
                              key: option.value,
                              domProps: { value: option.value },
                            },
                            [_vm._v(" " + _vm._s(option.text) + " ")]
                          )
                        }),
                        0
                      ),
                      _vm.errors.markingPeriod
                        ? _c(
                            "span",
                            { staticClass: "form-text invalid-feedback" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.errors.markingPeriod) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("label", { staticClass: "w-100" }, [
                        _c("span", { staticClass: "kt-font-danger" }, [
                          _vm._v("* "),
                        ]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("pages.teacher.assignments.category")
                            ) +
                            " "
                        ),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.courseWork.gradeTemplateCategoryId,
                              expression: "courseWork.gradeTemplateCategoryId",
                            },
                          ],
                          staticClass: "form-control",
                          class: {
                            "is-invalid": _vm.errors.courseWorkCategory,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.courseWork,
                                "gradeTemplateCategoryId",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.gradeTemplateCategories, function (t) {
                          return _c(
                            "option",
                            {
                              key: `gradeTemplateCategories_${t.gradeTemplateCategoryId}`,
                              domProps: { value: t.gradeTemplateCategoryId },
                            },
                            [_vm._v(" " + _vm._s(t.categoryName) + " ")]
                          )
                        }),
                        0
                      ),
                      _vm.errors.courseWorkCategory
                        ? _c(
                            "span",
                            { staticClass: "form-text invalid-feedback" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.errors.courseWorkCategory) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("label", [_vm._v("Due Date")]),
                        _c(
                          "b-input-group",
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-form-datepicker", {
                                  attrs: {
                                    "button-only": "",
                                    autocomplete: "off",
                                    "reset-button": "",
                                    locale: "en-US",
                                  },
                                  model: {
                                    value: _vm.courseWork.dueDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.courseWork, "dueDate", $$v)
                                    },
                                    expression: "courseWork.dueDate",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              staticClass: "form-control kt-input",
                              class: { "is-invalid": _vm.errors.dueDate },
                              attrs: { type: "date", autocomplete: "off" },
                              model: {
                                value: _vm.courseWork.dueDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.courseWork, "dueDate", $$v)
                                },
                                expression: "courseWork.dueDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("label", [_vm._v("Due Time")]),
                        _c(
                          "b-input-group",
                          [
                            _c(
                              "b-input-group-prepend",
                              [
                                _c("b-form-timepicker", {
                                  attrs: {
                                    "show-seconds": false,
                                    hour12: "",
                                    "reset-button": "",
                                    "button-only": "",
                                    locale: "en",
                                  },
                                  model: {
                                    value: _vm.courseWork.dueTime,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.courseWork, "dueTime", $$v)
                                    },
                                    expression: "courseWork.dueTime",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("b-form-input", {
                              class: _vm.errors.dueDate
                                ? "is-invalid form-control kt-input"
                                : "form-control kt-input",
                              attrs: { type: "time", autocomplete: "off" },
                              model: {
                                value: _vm.courseWork.dueTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.courseWork, "dueTime", $$v)
                                },
                                expression: "courseWork.dueTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.errors.dueDate
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "span",
                            { staticClass: "form-text invalid-feedback" },
                            [_vm._v(" " + _vm._s(_vm.errors.dueDate) + " ")]
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("span", { staticClass: "kt-font-danger" }, [
                        _vm._v("* "),
                      ]),
                      _c("label", [_vm._v("Type")]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.courseWork.courseWorkType,
                              expression: "courseWork.courseWorkType",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.courseWork,
                                "courseWorkType",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.courseWorkTypes, function (t) {
                          return _c(
                            "option",
                            {
                              key: `courseWorkTypes_${t.value}`,
                              attrs: { disabled: true },
                              domProps: { value: t.value },
                            },
                            [_vm._v(" " + _vm._s(t.display) + " ")]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("span", { staticClass: "kt-font-danger" }, [
                        _vm._v("* "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("pages.teacher.assignments.weight"))
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.courseWork.courseWorkWeight,
                            expression: "courseWork.courseWorkWeight",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.weight },
                        attrs: { type: "text" },
                        domProps: { value: _vm.courseWork.courseWorkWeight },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.courseWork,
                              "courseWorkWeight",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.errors.weight
                        ? _c(
                            "div",
                            { staticClass: "form-text invalid-feedback" },
                            [_vm._v(" " + _vm._s(_vm.errors.weight) + " ")]
                          )
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("span", { staticClass: "kt-font-danger" }, [
                        _vm._v("* "),
                      ]),
                      _c("label", [
                        _vm._v(
                          _vm._s(_vm.$t("pages.teacher.assignments.maxPoints"))
                        ),
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.courseWork.maxPoints,
                            expression: "courseWork.maxPoints",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.maxPoints },
                        attrs: { type: "text" },
                        domProps: { value: _vm.courseWork.maxPoints },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.courseWork,
                              "maxPoints",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.errors.maxPoints
                        ? _c(
                            "div",
                            { staticClass: "form-text invalid-feedback" },
                            [_vm._v(" " + _vm._s(_vm.errors.maxPoints) + " ")]
                          )
                        : _vm._e(),
                    ]),
                    _vm.courseWork.extTopicId
                      ? _c("div", { staticClass: "col-md-3" }, [
                          _c("label", [
                            _vm._v(_vm._s(_vm.$t("generic.labels.topic"))),
                          ]),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.courseWork.extTopicId,
                                  expression: "courseWork.extTopicId",
                                },
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.courseWork,
                                    "extTopicId",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(_vm.googleTopics, function (t) {
                              return _c(
                                "option",
                                {
                                  key: `googleCourseTopic_${t.googleCourseTopicId}`,
                                  domProps: { value: t.extTopicId },
                                },
                                [_vm._v(" " + _vm._s(t.topicName) + " ")]
                              )
                            }),
                            0
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-md-12" }, [
                      _vm.courseWork.courseWorkType ==
                      "MULTIPLE_CHOICE_QUESTION"
                        ? _c("label", [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _vm._v(" Multiple Choice Question "),
                          ])
                        : _vm.courseWork.courseWorkType ==
                          "SHORT_ANSWER_QUESTION"
                        ? _c("label", [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _vm._v(" Short Answer Question "),
                          ])
                        : _c("label", [
                            _c("span", { staticClass: "kt-font-danger" }, [
                              _vm._v("* "),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.teacher.assignments.title")
                                ) +
                                " "
                            ),
                          ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.courseWork.courseWorkTitle,
                            expression: "courseWork.courseWorkTitle",
                          },
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": _vm.errors.courseWorkTitle },
                        attrs: { type: "text" },
                        domProps: { value: _vm.courseWork.courseWorkTitle },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.courseWork,
                              "courseWorkTitle",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm.errors.courseWorkTitle
                        ? _c(
                            "div",
                            { staticClass: "form-text invalid-feedback" },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.errors.courseWorkTitle) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm.courseWork.courseWorkType == "MULTIPLE_CHOICE_QUESTION"
                      ? _c(
                          "div",
                          { staticClass: "col-12 pt-5" },
                          _vm._l(
                            _vm.courseWork.multipleChoiceQuestion.choices,
                            function (choice, choiceIdx) {
                              return _c(
                                "div",
                                {
                                  key: `choice_${choiceIdx}`,
                                  staticClass: "form-group row",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "col-md-1 d-none d-sm-block",
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "kt-badge kt-badge--primary kt-badge--dot pull-right",
                                        staticStyle: {
                                          opacity: "0.5",
                                          "margin-top": "15px",
                                        },
                                      }),
                                    ]
                                  ),
                                  _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-input-icon kt-input-icon--right",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: choice.text,
                                              expression: "choice.text",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          class: { "is-invalid": false },
                                          attrs: {
                                            type: "text",
                                            placeholder: `Multiple Choice Option ${
                                              choiceIdx + 1
                                            }`,
                                          },
                                          domProps: { value: choice.text },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                choice,
                                                "text",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "kt-input-icon__icon kt-input-icon__icon--right",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.removeChoiceAtIndex(
                                                  choiceIdx
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("span", [
                                              _c("i", {
                                                staticClass: "la la-close",
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "col-md-5" }),
                                ]
                              )
                            }
                          ),
                          0
                        )
                      : _vm.courseWork.courseWorkType == "ASSIGNMENT"
                      ? [
                          _c("div", { staticClass: "col-12" }, [
                            _c("label", [_vm._v("Assignment Description")]),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.courseWork.courseWorkDescription,
                                  expression:
                                    "courseWork.courseWorkDescription",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { rows: "3" },
                              domProps: {
                                value: _vm.courseWork.courseWorkDescription,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.courseWork,
                                    "courseWorkDescription",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]
                      : _vm._e(),
                    _c("div", { staticClass: "col-md-3" }),
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("label", [
                        _vm._v(_vm._s(_vm.$t("generic.labels.state"))),
                      ]),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.courseWork.courseWorkState,
                              expression: "courseWork.courseWorkState",
                            },
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.courseWork,
                                "courseWorkState",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        _vm._l(_vm.courseWorkStates, function (state) {
                          return _c(
                            "option",
                            {
                              key: state.value,
                              domProps: { value: state.value },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      `pages.teacher.assignments.${state.display.toLowerCase()}`
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _vm.assignment.courseWorkState == "DRAFT"
                      ? [
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("label", [_vm._v("Publish Date")]),
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-form-datepicker", {
                                        attrs: {
                                          "button-only": "",
                                          autocomplete: "off",
                                          "reset-button": "",
                                          locale: "en-US",
                                        },
                                        model: {
                                          value: _vm.courseWork.publishDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.courseWork,
                                              "publishDate",
                                              $$v
                                            )
                                          },
                                          expression: "courseWork.publishDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    staticClass: "form-control kt-input",
                                    class: {
                                      "is-invalid": _vm.errors.publishDate,
                                    },
                                    attrs: {
                                      type: "date",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.courseWork.publishDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.courseWork,
                                          "publishDate",
                                          $$v
                                        )
                                      },
                                      expression: "courseWork.publishDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3" },
                            [
                              _c("label", [_vm._v("Publish Time")]),
                              _c(
                                "b-input-group",
                                [
                                  _c(
                                    "b-input-group-prepend",
                                    [
                                      _c("b-form-timepicker", {
                                        attrs: {
                                          "show-seconds": false,
                                          hour12: "",
                                          "button-only": "",
                                          "reset-button": "",
                                          locale: "en",
                                        },
                                        model: {
                                          value: _vm.courseWork.publishTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.courseWork,
                                              "publishTime",
                                              $$v
                                            )
                                          },
                                          expression: "courseWork.publishTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("b-form-input", {
                                    class: _vm.errors.publishDate
                                      ? "is-invalid form-control kt-input"
                                      : "form-control kt-input",
                                    attrs: {
                                      type: "time",
                                      autocomplete: "off",
                                    },
                                    model: {
                                      value: _vm.courseWork.publishTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.courseWork,
                                          "publishTime",
                                          $$v
                                        )
                                      },
                                      expression: "courseWork.publishTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.errors.publishDate
                            ? _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "span",
                                  { staticClass: "form-text invalid-feedback" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.errors.publishDate) + " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "kt-portlet__foot" }, [
                _c("div", { staticClass: "kt-form__actions" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm.isMultipleEdit
                      ? _c("div", { staticClass: "col-12" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              class: {
                                "kt-spinner kt-spinner--sm kt-spinner--light":
                                  _vm.saving,
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.saveAllCourseWork()
                                },
                              },
                            },
                            [
                              !_vm.saving
                                ? _c("i", { staticClass: "la la-save" })
                                : _vm._e(),
                              _vm._v(" Save To All "),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-danger ml-3",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteCourseWork()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "la la-trash" }),
                              _vm._v(" Delete All "),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "col-12" }, [
                          _vm.assignment.alternateLink && !_vm.isMultipleEdit
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-secondary",
                                  attrs: {
                                    href: _vm.assignment.alternateLink,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(" Show in Google ")]
                              )
                            : _vm._e(),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-label-success pull-right",
                              class: {
                                "kt-spinner kt-spinner--sm kt-spinner--light":
                                  _vm.saving,
                              },
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.saveCourseWork()
                                },
                              },
                            },
                            [
                              !_vm.saving
                                ? _c("i", { staticClass: "la la-save" })
                                : _vm._e(),
                              _vm._v(" Save "),
                            ]
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "btn btn-label-danger mr-3 pull-right",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.deleteCourseWork()
                                },
                              },
                            },
                            [
                              _c("i", { staticClass: "la la-trash" }),
                              _vm._v(" Delete "),
                            ]
                          ),
                        ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }