<template>
<div
    v-if="googleCourse"
    :key="`key_${key}`"
>
    <!-- Content -->
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div
            v-if="isMultipleEdit"
            class="kt-portlet"
        >
            <div>
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3 class="kt-portlet__head-title">
                            Multi Course Assignment
                            <small>
                                Editing {{ linkedAssignments.length }} course assignments
                            </small>
                        </h3>
                    </div>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-group">
                            <div
                                v-if="canApplyToAll"
                                class="form-group row mb-0 mr-3 mt-1"
                            >
                                <label class="col-9 col-form-label text-right">
                                    Save to all courses
                                </label>
                                <div class="col-3">
                                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--brand">
                                        <label>
                                            <input
                                                v-model="isMultipleEdit"
                                                type="checkbox"
                                                checked="checked"
                                            >
                                            <span />
                                        </label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <GoogleCourseWork
                        v-if="primaryAssignment && primaryAssignment.googleCourseId"
                        :course="primaryAssignment.googleCourse"
                        :assignment="primaryAssignment"
                        :linked-assignments="linkedAssignments"
                        :idx="0"
                        :is-multiple-edit="isMultipleEdit && linkedAssignments.length > 1"
                        :on-course-work-saved="onCourseWorkSaved"
                    />
                </div>
            </div>
        </div>
        <div
            v-else
            class="kt-portlet"
        >
            <b-tabs
                pills
                class="kt-portlet__body"
                nav-class="mb-0"
            >
                <b-tab
                    v-for="(assignment, index) in linkedAssignments"
                    :key="`googleCourseWorkId_${assignment.googleCourseWorkId}_${key}`"
                    v-model="activeTabIndex"
                    :active="activeTabIndex == index"
                    :title="assignment.googleCourse.googleCourseName"
                >
                    <GoogleCourseWork
                        :course="assignment.googleCourse"
                        :assignment="assignment"
                        :linked-assignments="linkedAssignments"
                        :idx="index"
                        :is-multiple-edit="isMultipleEdit && linkedAssignments.length > 1"
                        :on-course-work-saved="onCourseWorkSaved"
                    />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import Vue from 'vue';
import { courseWorkMixins } from '../../store/mixins/courseWorkMixins';
import courseMixins from '../../store/mixins/courseMixins';
import GoogleCourseWork from './GoogleCourseWork.vue';
import * as network from '../../network';

const Component = Vue.extend({
    name: 'GoogleCourseAssignmentEdit',
    components: {
        GoogleCourseWork,
    },
    mixins: [
        courseMixins,
        courseWorkMixins,
    ],
    data() {
        return {
            key: 0,
            activeTabIndex: 0,
            isMultipleEdit: true,
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            googleCourseGradeCategories: (state) => state.database.googleCourseGradeCategories,
            googleCourseTopics: (state) => state.database.googleCourseTopics,
            deviceType: (state) => state.deviceType,
        }),
        canApplyToAll() {
            const v = this;
            return v.linkedAssignments.length > 1;
        },
        googleCourseWork() {
            return this.$_courseWorkMixins_getGoogleCourseWorkFromRoute();
        },
        googleCourse() {
            const course = this.$_courseMixins_getCourseFromRoute();
            if (!course) return null;
            const { googleCourseId } = course;
            if (!googleCourseId) return null;
            return this.$store.state.database.googleCourses.find((c) => c.googleCourseId == googleCourseId) || null;
        },
        primaryAssignment() {
            const [primary] = this.linkedAssignments;
            if (!primary) return null;
            return [0];
        },
        linkedAssignments() {
            if (!this.googleCourseWork || !this.googleCourse) return [];
            const { $store } = this;
            const { linkingGuid } = this.googleCourseWork;
            const { googleCourses } = $store.state.database;
            const output = $store.state.database.googleCourseWork
                .filter((a) => a.linkingGuid == linkingGuid && a.deleted == false)
                .map((cw) => {
                    const googleCourseWork = { ...cw };
                    const { googleCourseId } = cw;
                    googleCourseWork.googleCourse = googleCourses.find((x) => x.googleCourseId == googleCourseId) || null;
                    return googleCourseWork;
                });
            return output;
        },

    },
    methods: {
        getGoogleCourseFromAssignment(work) {
            if (!work || !work.googleCourseId) return null;
            return this.$store.state.database.googleCourses.find((c) => c.googleCourseId == work.googleCourseId) || null;
        },
        onCourseWorkSaved() {
            debugger;
        },
    },
});

export default Component;

</script>

<style scoped>
.nav-pills {
    margin-bottom: 0 !important;
}

.no-wrap-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
