<template>
<div v-if="(isMultipleEdit && isPrimary) || !isMultipleEdit">
    <div
        v-if="!isMultipleEdit"
        class="kt-separator kt-separator--space-md kt-separator--border-dashed"
    />
    <b-overlay
        no-center
        :show="saving"
    >
        <template #overlay>
            <div
                v-if="saving"
                class="kt-portlet__body"
            >
                <div class="text-center">
                    <b-icon
                        icon="stopwatch"
                        font-scale="3"
                        animation="cylon"
                    />

                    <h6 class="mt-4">
                        Saving changes...
                    </h6>
                </div>
            </div>
        </template>

        <!-- The body content -->
        <div class="kt-portlet__body pt-0">
            <div class="row form-group validated">
                <!-- first row -->
                <div class="col-md-3">
                    <span class="kt-font-danger">* </span>
                    <label>{{ $t("pages.teacher.assignments.markingPeriod") }}</label>
                    <select
                        v-model="courseWork.schoolTermMarkingPeriodId"
                        class="form-control"
                        :class="{ 'is-invalid': errors.markingPeriod }"
                    >
                        <option
                            v-for="option in markingPeriodSelect"
                            :key="option.value"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </select>
                    <span
                        v-if="errors.markingPeriod"
                        class="form-text invalid-feedback"
                    >
                        {{ errors.markingPeriod }}
                    </span>
                </div>
                <div class="col-md-3">
                    <label class="w-100">
                        <span class="kt-font-danger">* </span>
                        {{ $t("pages.teacher.assignments.category") }}
                    </label>
                    <select
                        v-model="courseWork.gradeTemplateCategoryId"
                        class="form-control"
                        :class="{ 'is-invalid': errors.courseWorkCategory }"
                    >
                        <option
                            v-for="t in gradeTemplateCategories"
                            :key="`gradeTemplateCategories_${t.gradeTemplateCategoryId}`"
                            :value="t.gradeTemplateCategoryId"
                        >
                            {{ t.categoryName }}
                        </option>
                    </select>
                    <span
                        v-if="errors.courseWorkCategory"
                        class="form-text invalid-feedback"
                    >
                        {{ errors.courseWorkCategory }}
                    </span>
                </div>
                <div class="col-md-3">
                    <label>Due Date</label>
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-form-datepicker
                                v-model="courseWork.dueDate"
                                button-only
                                autocomplete="off"
                                reset-button
                                locale="en-US"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                            v-model="courseWork.dueDate"
                            type="date"
                            class="form-control kt-input"
                            :class="{ 'is-invalid': errors.dueDate }"
                            autocomplete="off"
                        />
                    </b-input-group>
                </div>
                <div class="col-md-3">
                    <!-- Due Time -->
                    <label>Due Time</label>
                    <b-input-group>
                        <b-input-group-prepend>
                            <b-form-timepicker
                                v-model="courseWork.dueTime"
                                :show-seconds="false"
                                hour12
                                reset-button
                                button-only
                                locale="en"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                            v-model="courseWork.dueTime"
                            type="time"
                            :class="errors.dueDate
                                ? 'is-invalid form-control kt-input'
                                : 'form-control kt-input'
                            "
                            autocomplete="off"
                        />
                    </b-input-group>
                </div>

                <div
                    v-if="errors.dueDate"
                    class="col-12"
                >
                    <span class="form-text invalid-feedback">
                        {{ errors.dueDate }}
                    </span>
                </div>

                <!-- second row -->

                <div class="col-md-3">
                    <!-- courseWorkTypes -->
                    <span class="kt-font-danger">* </span>
                    <label>Type</label>
                    <select
                        v-model="courseWork.courseWorkType"
                        class="form-control"
                    >
                        <option
                            v-for="t in courseWorkTypes"
                            :key="`courseWorkTypes_${t.value}`"
                            :value="t.value"
                            :disabled="true"
                        >
                            {{ t.display }}
                        </option>
                    </select>
                </div>

                <div class="col-md-3">
                    <span class="kt-font-danger">* </span>
                    <label>{{ $t("pages.teacher.assignments.weight") }}</label>
                    <input
                        v-model="courseWork.courseWorkWeight"
                        class="form-control"
                        :class="{ 'is-invalid': errors.weight }"
                        type="text"
                    >
                    <div
                        v-if="errors.weight"
                        class="form-text invalid-feedback"
                    >
                        {{ errors.weight }}
                    </div>
                </div>
                <div class="col-md-3">
                    <span class="kt-font-danger">* </span>
                    <label>{{ $t("pages.teacher.assignments.maxPoints") }}</label>
                    <input
                        v-model="courseWork.maxPoints"
                        class="form-control"
                        :class="{ 'is-invalid': errors.maxPoints }"
                        type="text"
                    >
                    <div
                        v-if="errors.maxPoints"
                        class="form-text invalid-feedback"
                    >
                        {{ errors.maxPoints }}
                    </div>
                </div>
                <div
                    v-if="courseWork.extTopicId"
                    class="col-md-3"
                >
                    <label>{{ $t("generic.labels.topic") }}</label>
                    <select
                        v-model="courseWork.extTopicId"
                        class="form-control"
                    >
                        <option
                            v-for="t in googleTopics"
                            :key="`googleCourseTopic_${t.googleCourseTopicId}`"
                            :value="t.extTopicId"
                        >
                            {{ t.topicName }}
                        </option>
                    </select>
                </div>

                <!-- third row -->
                <div class="col-md-12">
                    <label v-if="courseWork.courseWorkType == 'MULTIPLE_CHOICE_QUESTION'">
                        <span class="kt-font-danger">* </span>
                        Multiple Choice Question
                    </label>
                    <label
                        v-else-if="courseWork.courseWorkType == 'SHORT_ANSWER_QUESTION'
                        "
                    >
                        <span class="kt-font-danger">* </span>
                        Short Answer Question
                    </label>
                    <label v-else>
                        <span class="kt-font-danger">* </span>
                        {{ $t("pages.teacher.assignments.title") }}
                    </label>

                    <input
                        v-model="courseWork.courseWorkTitle"
                        class="form-control"
                        :class="{ 'is-invalid': errors.courseWorkTitle }"
                        type="text"
                    >
                    <div
                        v-if="errors.courseWorkTitle"
                        class="form-text invalid-feedback"
                    >
                        {{ errors.courseWorkTitle }}
                    </div>
                </div>

                <!-- fourth row -->
                <div
                    v-if="courseWork.courseWorkType == 'MULTIPLE_CHOICE_QUESTION'"
                    class="col-12 pt-5"
                >
                    <div
                        v-for="(choice, choiceIdx) in courseWork.multipleChoiceQuestion
                            .choices"
                        :key="`choice_${choiceIdx}`"
                        class="form-group row"
                    >
                        <div class="col-md-1 d-none d-sm-block">
                            <span
                                class="kt-badge kt-badge--primary kt-badge--dot pull-right"
                                style="opacity: 0.5; margin-top: 15px"
                            />
                        </div>
                        <div class="col-md-6">
                            <div class="kt-input-icon kt-input-icon--right">
                                <input
                                    v-model="choice.text"
                                    type="text"
                                    class="form-control"
                                    :class="{ 'is-invalid': false }"
                                    :placeholder="`Multiple Choice Option ${choiceIdx + 1
                                    }`"
                                >
                                <a
                                    href="#"
                                    class="kt-input-icon__icon kt-input-icon__icon--right"
                                    @click.stop.prevent="removeChoiceAtIndex(choiceIdx)"
                                >
                                    <span><i class="la la-close" /></span>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-5" />
                    </div>
                </div>
                <template v-else-if="courseWork.courseWorkType == 'ASSIGNMENT'">
                    <div class="col-12">
                        <!-- Description -->
                        <label>Assignment Description</label>
                        <textarea
                            v-model="courseWork.courseWorkDescription"
                            class="form-control"
                            rows="3"
                        />
                    </div>
                </template>

                <!-- fifth row -->

                <div class="col-md-3" />
                <div class="col-md-3">
                    <label>{{ $t("generic.labels.state") }}</label>
                    <select
                        v-model="courseWork.courseWorkState"
                        class="form-control"
                    >
                        <option
                            v-for="state in courseWorkStates"
                            :key="state.value"
                            :value="state.value"
                        >
                            {{
                                $t(
                                    `pages.teacher.assignments.${state.display.toLowerCase()}`
                                )
                            }}
                        </option>
                    </select>
                </div>
                <template v-if="assignment.courseWorkState == 'DRAFT'">
                    <div class="col-md-3">
                        <!-- Publish Date -->
                        <label>Publish Date</label>
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-datepicker
                                    v-model="courseWork.publishDate"
                                    button-only
                                    autocomplete="off"
                                    reset-button
                                    locale="en-US"
                                />
                            </b-input-group-prepend>
                            <b-form-input
                                v-model="courseWork.publishDate"
                                type="date"
                                class="form-control kt-input"
                                :class="{ 'is-invalid': errors.publishDate }"
                                autocomplete="off"
                            />
                        </b-input-group>
                    </div>
                    <div class="col-md-3">
                        <!-- Publish Time -->
                        <label>Publish Time</label>
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-form-timepicker
                                    v-model="courseWork.publishTime"
                                    :show-seconds="false"
                                    hour12
                                    button-only
                                    reset-button
                                    locale="en"
                                />
                            </b-input-group-prepend>
                            <b-form-input
                                v-model="courseWork.publishTime"
                                type="time"
                                :class="errors.publishDate
                                    ? 'is-invalid form-control kt-input'
                                    : 'form-control kt-input'
                                "
                                autocomplete="off"
                            />
                        </b-input-group>
                    </div>

                    <div
                        v-if="errors.publishDate"
                        class="col-12"
                    >
                        <span class="form-text invalid-feedback">
                            {{ errors.publishDate }}
                        </span>
                    </div>
                </template>
            </div>
        </div>

        <!-- The body content -->
        <div class="kt-portlet__foot">
            <div class="kt-form__actions">
                <div class="row">
                    <div
                        v-if="isMultipleEdit"
                        class="col-12"
                    >
                        <button
                            type="button"
                            class="btn btn-primary"
                            :class="{
                                'kt-spinner kt-spinner--sm kt-spinner--light': saving,
                            }"
                            @click.stop.prevent="saveAllCourseWork()"
                        >
                            <i
                                v-if="!saving"
                                class="la la-save"
                            />
                            Save To All
                        </button>
                        <a
                            href="#"
                            class="btn btn-danger ml-3"
                            @click.stop.prevent="deleteCourseWork()"
                        >
                            <i class="la la-trash" />
                            Delete All
                        </a>
                    </div>
                    <div
                        v-else
                        class="col-12"
                    >
                        <a
                            v-if="assignment.alternateLink && !isMultipleEdit"
                            :href="assignment.alternateLink"
                            class="btn btn-secondary"
                            target="_blank"
                        >
                            Show in Google
                        </a>
                        <button
                            type="button"
                            class="btn btn-label-success pull-right"
                            :class="{
                                'kt-spinner kt-spinner--sm kt-spinner--light': saving,
                            }"
                            @click.stop.prevent="saveCourseWork()"
                        >
                            <i
                                v-if="!saving"
                                class="la la-save"
                            />
                            Save
                        </button>
                        <a
                            href="#"
                            class="btn btn-label-danger mr-3 pull-right"
                            @click.stop.prevent="deleteCourseWork()"
                        >
                            <i class="la la-trash" />
                            Delete
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </b-overlay>
</div>
</template>

<script lang="ts">
import { mapState, mapMutations } from 'vuex';
import moment from 'moment';
import async from 'async';
import Types from '../../store/Types';
import courseMixins from '../../store/mixins/courseMixins';

export default {
    name: 'GoogleCourseWork',
    mixins: [
        courseMixins,
    ],
    props: {
        idx: {
            type: Number,
            required: true,
        },
        course: {
            type: Object,
            required: true,
        },
        assignment: {
            type: Object,
            required: true,
        },
        onCourseWorkSaved: {
            type: Function,
            required: true,
        },
        linkedAssignments: {
            type: Array,
            required: true,
        },
        isMultipleEdit: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            selectedGoogleCourses: [],
            tabIndex: 0,
            createdAssignments: [],
            saving: false,
            applyToAll: false,
            courseWork: {
                assigneeMode: 'ALL_STUDENTS',
                courseWorkType: 'ASSIGNMENT',
                courseWorkState: 'PUBLISHED',
                courseWorkTitle: null,
                courseWorkDescription: null,
                submissionModificationMode: 'MODIFIABLE_UNTIL_TURNED_IN',
                extTopicId: null,
                courseWorkWeight: 1,
                gradeTemplateCategoryId: 0,
                schoolTermMarkingPeriodId: null,
                averageExclusiveForMp: true,
                maxPoints: 100,
                associatedWithDeveloper: true,
                dueDate: null,
                dueTime: null,
                publishDate: null,
                publishTime: null,
                multipleChoiceQuestion: {
                    choices: [
                        {
                            text: '',
                        },
                    ],
                },
            },
            errors: {
                courseWorkTitle: null,
                courseWorkCategory: null,
                courses: null,
                weight: null,
                maxPoints: null,
                publishDate: null,
                dueDate: null,
                markingPeriod: null,
            },
            courseWorkTypes: [
                {
                    value: 'ASSIGNMENT',
                    display: 'Assignment',
                },
                {
                    value: 'SHORT_ANSWER_QUESTION',
                    display: 'Short Answer Question',
                },
                {
                    value: 'MULTIPLE_CHOICE_QUESTION',
                    display: 'Multiple Choice Question',
                },
            ],
            courseWorkStates: [
                {
                    value: 'PUBLISHED',
                    display: 'Published',
                },
                {
                    value: 'DRAFT',
                    display: 'Draft',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            courseSectionGradeTemplates: (state) => state.database.courseSectionGradeTemplates,
            googleCourseTeachers: (state) => state.database.googleCourseTeachers,
            googleCourseCourseSections: (state) => state.database.googleCourseCourseSections,
            googleCourseGradeCategories: (state) => state.database.googleCourseGradeCategories,
            googleCourseTopics: (state) => state.database.googleCourseTopics,
            markingPeriods: (state) => state.database.markingPeriods,
            googleCourseWork: (state) => state.database.googleCourseWork,
            gradeTemplates: (state) => state.database.gradeTemplates,
            showQuickPanel: (state) => state.quickPanel.open,
            deviceType: (state) => state.deviceType,
        }),
        courseSection() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        isPrimary() {
            return this.idx == 0;
        },
        googleCourseId() {
            if (!this.assignment) return null;
            const { googleCourseId } = this.assignment;
            return googleCourseId || null;
        },
        currentCourse() {
            if (!this.assignment) return null;
            const { googleCourseId } = this.assignment;
            return (
                this.$store.state.database.googleCourses.find(
                    (a) => a.googleCourseId == googleCourseId,
                ) || null
            );
        },
        googleCategories() {
            if (!this.googleCourseId) return [];
            const { googleCourseId } = this;
            const googleCourseGradeCategories = this.googleCourseGradeCategories.filter(
                (a) => a.googleCourseId == googleCourseId,
            );

            googleCourseGradeCategories.unshift({
                googleCourseGradeCategoryId: 0,
                extGradeCategoryId: null,
                gradeCategoryName: 'No Category',
            });

            return googleCourseGradeCategories;
        },
        googleTopics() {
            const { googleCourseId } = this;
            const googleCourseTopics = this.googleCourseTopics.filter(
                (a) => a.googleCourseId == googleCourseId,
            );

            googleCourseTopics.unshift({
                googleCourseTopicId: 0,
                extTopicId: null,
                topicName: 'No Topic',
            });

            return googleCourseTopics;
        },
        gradeTemplateCategories() {
            const { gradeTemplate } = this;
            if (!gradeTemplate) return [];

            const categories = gradeTemplate.categories.slice();

            categories.unshift({
                gradeTemplateCategoryId: 0,
                categoryName: 'No Category',
            });

            return categories;
        },
        googleCourseWorks() {
            const { $store, googleCourseId } = this;
            return $store.state.database.googleCourseWork.filter(
                (g) => g.googleCourseId == googleCourseId,
            );
        },
        gradeTemplate() {
            const { courseSectionId } = this.courseSection;

            const t = this.courseSectionGradeTemplates.find((c) => c.courseSectionId == courseSectionId);
            if (!t) return null;
            return this.gradeTemplates.find((gt) => gt.gradeTemplateId == t.gradeTemplateId);
        },
        joinedDueDate() {
            const { dueDate, dueTime } = this.courseWork;
            if (!dueDate) return null;
            return `${dueDate} ${dueTime || '23:59'}`;
        },
        saveJoinedDueDate() {
            const { dueDate } = this.assignment;
            if (!dueDate) return null;
            const savedDueDate = this.fromZuluToMoment(this.assignment.dueDate);
            return savedDueDate.format('YYYY-MM-DD HH:mm');
        },
        markingPeriodSelect() {
            const { markingPeriods } = this;
            const mps = markingPeriods.map((mp) => ({
                value: mp.schoolTermMarkingPeriodId,
                text: mp.markingPeriod,
            }));
            mps.unshift({
                value: null,
                text: 'No Marking Period',
            });
            return mps;
        },
    },
    watch: {
        'courseWork.courseWorkState': {
            handler() {
                if (this.courseWork.courseWorkState == 'PUBLISHED') {
                    this.courseWork.publishDate = null;
                    this.courseWork.publishTime = null;
                }
            },
            deep: true,
        },
        'courseWork.courseWorkType': {
            handler() {
                this.showChoices();
            },
            deep: true,
        },
        'courseWork.multipleChoiceQuestion.choices': {
            handler() {
                this.showChoices();
            },
            deep: true,
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        ...mapMutations(['openQuickPanelForGoogleCourse']),
        showAssignmentAtIndex(idx) {
            this.tabIndex = idx;
        },
        showChoices() {
            if (this.courseWork.courseWorkType !== 'MULTIPLE_CHOICE_QUESTION') return;
            const { choices } = this.courseWork.multipleChoiceQuestion;
            if (!choices) return;
            const submitted = choices.filter((c) => (c.text || '').trim() !== '');
            if (submitted.length == choices.length) {
                choices.push({
                    text: '',
                });
            }
        },
        removeChoiceAtIndex(index) {
            const { choices } = this.courseWork.multipleChoiceQuestion;
            choices.splice(index, 1);
        },
        openAssignment(cw) {
            if (cw.alternateLink) {
                window.open(cw.alternateLink, '_blank');
            } else {
                this.showNotification('Google doesnt provide a link for this assignment until it is published.');
            }
        },
        populate() {
            const { courseWork, assignment } = this;
            Object.keys(assignment).forEach((key) => {
                if (key in courseWork) {
                    courseWork[key] = assignment[key];
                }
            });

            const assignmentDueDate = this.fromZuluToMoment(assignment.dueDate);
            if (assignment.dueDate) {
                courseWork.dueDate = assignmentDueDate.format('YYYY-MM-DD');
                courseWork.dueTime = assignmentDueDate.format('HH:mm');
            }
            if (assignment.publishDate) {
                courseWork.publishDate = moment(assignment.publishDate).format('YYYY-MM-DD');
                courseWork.publishTime = moment(assignment.publishDate).format('HH:mm');
            }
        },
        openGoogleCourse(googleCourseId) {
            const params = { googleCourseId };
            if (this.showQuickPanel) return this.closeQuickPanel();
            this.openQuickPanelForGoogleCourse(params);
        },
        isNumeric(n) {
            // eslint-disable-next-line no-restricted-globals
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        isValid() {
            const { courseWork, errors, isNumeric } = this;

            errors.courseWorkTitle = '';
            errors.courseWorkCategory = '';
            errors.courses = '';
            errors.weight = '';
            errors.maxPoints = '';
            errors.publishDate = '';
            errors.dueDate = '';
            errors.markingPeriod = '';

            let isValid = true;
            if (
                !courseWork.courseWorkTitle
                || courseWork.courseWorkTitle.length == 0
            ) {
                errors.courseWorkTitle = 'Title is required';
                isValid = false;
            }
            if (!courseWork.gradeTemplateCategoryId) {
                errors.courseWorkCategory = 'Category selection is required';
                isValid = false;
            }

            if (!courseWork.courseWorkWeight) {
                errors.weight = 'Weight is required';
                isValid = false;
            }

            if (!courseWork.schoolTermMarkingPeriodId) {
                errors.markingPeriod = 'Marking Period is required';
                isValid = false;
            }

            if (!isNumeric(courseWork.courseWorkWeight)) {
                if (!errors.weight) {
                    errors.weight = 'Weight must be a number';
                    isValid = false;
                }
            }
            if (!courseWork.maxPoints) {
                errors.maxPoints = 'Max Points is required';
                isValid = false;
            }
            if (!/^\d+$/.test(courseWork.maxPoints)) {
                if (!errors.maxPoints) {
                    errors.maxPoints = 'Max Points must be a positive number';
                    isValid = false;
                }
            }
            const now = moment();
            const format = 'YYYY-MM-DD HH:mm';
            if (courseWork.dueTime && !courseWork.dueDate) {
                errors.dueDate = 'If submitting a time, you must also submit a date';
                isValid = false;
            } else if (this.joinedDueDate) {
                const newDueDate = moment(this.joinedDueDate, format);
                if (newDueDate.isBefore(now)) {
                    errors.dueDate = `Unfortunately, google says due date's must be atleast 4 hours in the future when using the API.
                    You can set the due date via the google classroom website.`;
                    isValid = false;
                }
            }

            if (courseWork.publishTime && !courseWork.publishDate) {
                errors.publishDate = 'If submitting a time, you must also submit a date';
                isValid = false;
            } else if (courseWork.publishDate) {
                const publishDate = moment(
                    `${courseWork.publishDate} ${courseWork.publishTime || '23:59'}`,
                    format,
                );
                if (publishDate.isBefore(now)) {
                    errors.publishDate = 'Publish date cannot be in the past.';
                    isValid = false;
                }
                if (this.joinedDueDate) {
                    const newDueDate = moment(this.joinedDueDate, format);
                    if (newDueDate.isBefore(publishDate)) {
                        errors.publishDate = 'Publish date cannot be before the due date';
                        isValid = false;
                    }
                }
            }

            return isValid;
        },
        fromZulu(zuluTimeString) {
            if (!zuluTimeString) return null;
            const formatString = 'YYYY-MM-DD HH:mm';
            return this.fromZuluToMoment(zuluTimeString).format(formatString);
        },
        fromZuluToMoment(zuluTimeString) {
            if (!zuluTimeString) return null;
            const timestamp = new Date(zuluTimeString);
            return moment(timestamp);
        },
        saveAllCourseWork() {
            const { saveSingleCourseWork, linkedAssignments } = this;
            const { showError, courseWork, onCourseWorkSaved } = this;

            const v = this;
            v.saving = true;

            async.eachSeries(linkedAssignments, (assignment, next) => {
                saveSingleCourseWork(assignment, courseWork, next);
            }, (err) => {
                v.saving = false;
                if (err) return showError(err);
                onCourseWorkSaved(false);
            });
        },
        createRequestParameters(assignment, courseWork) {
            const googleCourseWork = {
                courseWorkState: {
                    externalName: 'state',
                    modified: courseWork.courseWorkState !== assignment.courseWorkState,
                    value: courseWork.courseWorkState,
                },
                courseWorkTitle: {
                    externalName: 'title',
                    modified: courseWork.courseWorkTitle !== assignment.courseWorkTitle,
                    value: courseWork.courseWorkTitle,
                },
                courseWorkDescription: {
                    externalName: 'description',
                    modified:
                        courseWork.courseWorkDescription
                        !== assignment.courseWorkDescription,
                    value: courseWork.courseWorkDescription,
                },
                extTopicId: {
                    externalName: 'topicId',
                    modified: courseWork.extTopicId !== assignment.extTopicId,
                    value: courseWork.extTopicId,
                },
                maxPoints: {
                    externalName: 'maxPoints',
                    modified: courseWork.maxPoints !== assignment.maxPoints,
                    value: courseWork.maxPoints,
                },
                dueDate: {
                    externalName: 'dueDate',
                    modified: false,
                    value: '',
                },
                publishDate: {
                    externalName: 'scheduledTime',
                    modified: courseWork.publishDate !== assignment.publishDate,
                    value: courseWork.publishDate,
                },
            };
            const googleCourseWorkProperties = {
                googleCourseWorkId: assignment.googleCourseWorkId,
                gradeTemplateCategoryId: courseWork.gradeTemplateCategoryId,
                courseWorkWeight: courseWork.courseWorkWeight,
                schoolTermMarkingPeriodId: courseWork.schoolTermMarkingPeriodId,
                averageExclusiveForMp: courseWork.averageExclusiveForMp,
            };
            const dateFormat = 'YYYY-MM-DD HH:mm';
            if (courseWork.dueDate) {
                const { joinedDueDate, saveJoinedDueDate } = this;
                if (joinedDueDate != saveJoinedDueDate) {
                    googleCourseWork.dueDate.modified = true;
                    const dueDate = moment(joinedDueDate, dateFormat).utc();
                    googleCourseWork.dueDate.value = dueDate.format(dateFormat);
                }
            }
            if (courseWork.publishDate) {
                googleCourseWork.publishDate.modified = true;
                const publishDate = moment(
                    `${courseWork.publishDate} ${courseWork.publishTime || '00:00'}`,
                    dateFormat,
                ).utc();
                googleCourseWork.publishDate.value = publishDate.format(dateFormat);
            }
            if (
                googleCourseWork.courseWorkState.modified
                && googleCourseWork.courseWorkState.value === 'PUBLISHED'
            ) {
                googleCourseWork.publishDate.modified = true;
                googleCourseWork.publishDate.value = '';
            }
            return { googleCourseWork, googleCourseWorkProperties };
        },
        saveCourseWork() {
            const { assignment, courseWork } = this;
            const { showError, onCourseWorkSaved, saveSingleCourseWork } = this;

            const v = this;
            if (v.saving == true) return;

            saveSingleCourseWork(assignment, courseWork, (err) => {
                if (err) return showError(err);
                onCourseWorkSaved(false);
            });
        },
        saveSingleCourseWork(assignment, courseWork, onCompleted) {
            const checkValidity = this.isValid();
            if (!checkValidity) return;
            this.saving = true;

            const params = this.createRequestParameters(assignment, courseWork);
            const { googleCourseWork, googleCourseWorkProperties } = params;
            const { googleCourseId, googleCourseWorkId } = assignment;

            debugger;

            this.$store.dispatch(Types.actions.EDIT_GOOGLE_COURSE_WORK, {
                googleCourseId,
                googleCourseWorkId,
                googleCourseWork,
                googleCourseWorkProperties,
                callback(err) {
                    onCompleted(err);
                },
            });
        },
        deleteCourseWork() {
            const v = this;
            const { googleCourseName } = v.currentCourse;
            const { assignment, onCourseWorkSaved } = v;
            const { courseWorkTitle } = v.assignment;
            const { linkedAssignments, $store, showError } = v;

            if (v.saving) return;

            let message = `Are you sure you want to delete ${courseWorkTitle} from ${googleCourseName}? This action is permanent.`;
            if (v.isMultipleEdit) {
                message = 'Are you sure you want to delete these assignments? This action is permanent.';
            }
            v.$bvModal
                .msgBoxConfirm(message, {
                    title: 'Please Confirm Deletion',
                    size: 'md',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'DELETE',
                    cancelTitle: 'Cancel',
                    footerClass: 'p-2',
                    hideHeaderClose: true,
                })
                .then((approved) => {
                    if (!approved) return;
                    v.saving = true;
                    if (v.isMultipleEdit) {
                        async.eachOfLimit(linkedAssignments, 5, (cw, key, next) => {
                            const params = {
                                googleCourseWorkId: cw.googleCourseWorkId,
                                googleCourseId: cw.googleCourseId,
                                callback: next,
                            };
                            $store.dispatch(Types.actions.DELETE_GOOGLE_COURSE_WORK, params);
                        }, (err) => {
                            v.saving = false;
                            if (err) return showError(err);
                            onCourseWorkSaved(true);
                        });
                    } else {
                        $store.dispatch(Types.actions.DELETE_GOOGLE_COURSE_WORK, {
                            googleCourseWorkId: assignment.googleCourseWorkId,
                            googleCourseId: assignment.googleCourseId,
                            callback(err) {
                                v.saving = false;
                                if (err) return showError(err);
                                onCourseWorkSaved(true);
                            },
                        });
                    }
                });
        },
        back() {
            this.$router.back();
        },
    },
};
</script>

<style scoped>
tbody label.kt-checkbox {
  width: 100%;
}

tbody label.kt-checkbox span {
  margin-top: 10px;
}

tbody .kt-checkbox.kt-checkbox--single {
  height: auto;
  margin-bottom: 0;
}

.sg-info-container {
  display: flex;
  flex-direction: row;
  padding-top: 5px;
  padding-bottom: 20px;
  margin-bottom: 10px;
  border-bottom: 1px #f0f0f0 solid;
}

.sg-info-item {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.sg-info-item.first {
  padding: 0px 10px 0px 0px;
  border-right: 1px #e2e5ec solid;
}

.sg-info-item.last {
  padding: 0px 0px 0px 10px;
  border-left: 1px #e2e5ec solid;
}

span.sg-info-title {
  font-weight: 500;
  font-size: 1.1rem;
  margin-right: 20px;
}

span.sg-info-detail {
  font-weight: 300;
  font-size: 1.1rem;
}

.kt-widget28__tab-item {
  padding-bottom: 20px;
}

span.sg-switch {
  display: flex;
  width: 100%;
  padding: 0.65rem 0rem 0rem 0rem;
  flex-direction: row-reverse;
}

td.delta-warning {
  vertical-align: middle;
  padding-right: 1em;
  font-size: 20px;
}

.error {
  border: 1px red solid;
}

.form-group.validated>div {
  padding-top: 1.2rem;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.no-wrap-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
